<template>
  <TTView>
    <VRow>
      <VCol>
        <InvitationsTableContainer />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import InvitationsTableContainer from '../../components/invitations/InvitationsTableContainer.vue';

export default {
  name: 'Invitations',

  components: {
    InvitationsTableContainer,
  },
};
</script>
