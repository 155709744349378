<template>
  <InvitationsTable
    :companies="companies"
    :invitations="invitations"
    :loading="loading"
    :total="total"
    @pagination="handlePagination"
  >
    <template #topActions>
      <VBtn
        color="primary"
        :to="{ name : Names.R_INVITATION_CREATE }"
      >
        <VIcon left>
          fal fa-plus
        </VIcon>

        Новое приглашение
      </VBtn>

      <VDialog
        v-model="confirmDialog"
        max-width="500px"
        @click:outside="handleCancel"
      >
        <VCard>
          <VCardTitle class="headline">
            Удалить приглашение
          </VCardTitle>

          <VCardText>
            Приглашение  <span class="red--text">{{ invitation.id }}</span> будет удалено! <br>
            Действительно хотите удалить приглашение?
          </VCardText>

          <VCardActions>
            <VSpacer />

            <VBtn
              text
              @click="handleCancel"
            >
              Нет
            </VBtn>

            <VBtn
              color="red darken-1"
              text
              @click="handleDelete"
            >
              Да
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </template>

    <template #rowActions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            dark
            color="blue"
            class="mx-1"
            :to="{ name : Names.R_INVITATION, params : { invitationId : item.id } }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            dark
            color="orange"
            class="mx-1"
            :to="{ name : Names.R_INVITATION_EDIT, params : { invitationId : item.id } }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            dark
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click.stop="deleteInvitation(item)"
          >
            <VIcon small>
              fal fa-trash
            </VIcon>
          </VBtn>
        </template>

        <span>Удаление</span>
      </VTooltip>
    </template>
  </InvitationsTable>
</template>

<script>
import { DEFAULT_INVITATION } from './common';
import InvitationsTable from './InvitationsTable.vue';

export default {
  name: 'InvitationsTableContainer',

  components: {
    InvitationsTable,
  },

  inject: ['Names'],

  data() {
    return {
      confirmDialog: false,
      companies: [],
      invitations: [],
      invitation: { ...DEFAULT_INVITATION },
      loading: false,
      pagination: {
        offset: 0,
        limit: 10,
      },
      total: 0,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const { pagination: data } = this;
        const [companiesResponse, invitationResponse] = await Promise.all([
          this.$di.api.Account.indexCompany(),
          this.$di.api.Invitations.InvitationIndex(data),
        ]);

        this.companies = companiesResponse.companies || [];
        this.invitations = invitationResponse.invitations || [];
        this.total = invitationResponse.total || 0;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    deleteInvitation(item) {
      this.confirmDialog = true;
      this.invitation = { ...item };
    },

    async handleDelete() {
      try {
        this.loading = true;
        const { id } = this.invitation;
        const data = { id };

        await this.$di.api.Invitations.InvitationDelete(data);

        this.resetInvitationEdit();
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetch();
      }
    },

    handleCancel() {
      this.resetInvitationEdit();
    },

    resetInvitationEdit() {
      this.confirmDialog = false;
      this.formDialog = false;
      this.invitation = { ...DEFAULT_INVITATION };
    },

    async handlePagination({ page, itemsPerPage, itemsLength }) {
      try {
        this.loading = true;

        const limit = itemsPerPage === -1 ? itemsLength : itemsPerPage;
        const offset = page > 0 ? (page - 1) * limit : 0;

        this.pagination = { offset, limit };

        const data = this.pagination;

        const invitationResponse = await this.$di.api.Invitations.InvitationIndex(data);

        this.invitations = invitationResponse.invitations || [];
        this.total = invitationResponse.total || 0;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
