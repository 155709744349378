/* eslint-disable import/prefer-default-export */

const DEFAULT_INVITATION = {
  id: null,
  companyId: null,
  serviceName: null,
  visibility: null,
  defaults: null,
  schema: null,
};

export { DEFAULT_INVITATION };

export const VISIBILITY_ADMIN = 'VISIBILITY_ADMIN';
export const VISIBILITY_ALL = 'VISIBILITY_ALL';
export const VISIBILITY_INVALID = 'VISIBILITY_INVALID';
export const VISIBILITY_NONE = 'VISIBILITY_NONE';

export const VISIBILITIES = [VISIBILITY_ADMIN, VISIBILITY_ALL, VISIBILITY_INVALID, VISIBILITY_NONE];
export const VISIBILITIES_LIST = [
  {
    id: 1,
    title: 'Администратору',
    value: VISIBILITY_ADMIN,
  },
  {
    id: 2,
    title: 'Всем',
    value: VISIBILITY_ALL,
  },
  {
    id: 3,
    title: 'Никому',
    value: VISIBILITY_NONE,
  },
];
