var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('InvitationsTable',{attrs:{"companies":_vm.companies,"invitations":_vm.invitations,"loading":_vm.loading,"total":_vm.total},on:{"pagination":_vm.handlePagination},scopedSlots:_vm._u([{key:"topActions",fn:function(){return [_c('VBtn',{attrs:{"color":"primary","to":{ name : _vm.Names.R_INVITATION_CREATE }}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" fal fa-plus ")]),_vm._v(" Новое приглашение ")],1),_c('VDialog',{attrs:{"max-width":"500px"},on:{"click:outside":_vm.handleCancel},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('VCard',[_c('VCardTitle',{staticClass:"headline"},[_vm._v(" Удалить приглашение ")]),_c('VCardText',[_vm._v(" Приглашение "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.invitation.id))]),_vm._v(" будет удалено! "),_c('br'),_vm._v(" Действительно хотите удалить приглашение? ")]),_c('VCardActions',[_c('VSpacer'),_c('VBtn',{attrs:{"text":""},on:{"click":_vm.handleCancel}},[_vm._v(" Нет ")]),_c('VBtn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.handleDelete}},[_vm._v(" Да ")])],1)],1)],1)]},proxy:true},{key:"rowActions",fn:function(ref){
var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","color":"blue","to":{ name : _vm.Names.R_INVITATION, params : { invitationId : item.id } }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","color":"orange","to":{ name : _vm.Names.R_INVITATION_EDIT, params : { invitationId : item.id } }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактирование")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteInvitation(item)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удаление")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }